import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'outsiders-theme',
            'disable-default-analytics': true,
            'accordion-month-header': { arrowSvgName: 'icon-chevron' },
        });
    });
});
