import 'jquery-address';
import 'owl.carousel/dist/owl.carousel';
import '@situation/setdesign.common';
import '@situation/setdesign.directives.owl-a11y';
import '@situation/setdesign.directives.sticky-header';
import '@situation/setdesign.directives.toggle';
import '@situation/setdesign.directives.accessibility';
import '@situation/setdesign.nav-menu';
import '@situation/setdesign.modal';
import '@situation/setdesign.video';
import '@situation/setdesign.tag-manager';
// import '@situation/setdesign.read-more';
import '@situation/setdesign.accordion';
import '@situation/setdesign.sub-nav';
import '@situation/setdesign.media-carousel';
import '@situation/setdesign.content-slider';
import '@situation/setdesign.responsive-picture';
import '@situation/setdesign.lazy-image';
// import '@situation/setdesign.message';
// import '@situation/setdesign.filters';
import '@situation/setdesign.stream-selector';
import '@situation/vc-library';
import '@situation/orchestrator';
import { importAll } from '@situation/setdesign.util';

importAll(require.context('../images', true));
importAll(require.context('../fonts', true));
importAll(require.context('./components', false));
